export default {
  init() {
    $('.menuBtn').click(function() {
      event.preventDefault();
      $(this).toggleClass('act');

      if($(this).hasClass('act')) {
        $('.nav-primary').addClass('act');
      }

      else {
        $('.nav-primary').removeClass('act');
      }
	});

    $('.testimonials-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
    });

    $('.home-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      touchMove: false,
      arrows: false,
    });

    $('.bubble a').click(function() {
      event.preventDefault();
      $('.active').removeClass('active');
      var slide = $(this).attr('id');
      $('.home-slider').slick('slickGoTo', slide);
    });

    $('.light-slider').lightSlider({
      item: 1,
      useCSS: true,
      pause: 5000,
      loop: true,
      enableTouch: false,
      enableDrag: false,
      freeMove: false,
      onBeforeSlide: function (el) {
        var current = el.getCurrentSlideCount();
        var total = el.getTotalSlideCount();
        $('#current').text(current);
        $('#total').text(total);
      }, 
    });

    $('.calculator-hide').accrue({
      mode: 'amortization',
      operation: 'button',
    });

    // Mortgage Amount
    var insurance = 0;

    $('#forminator-field-currency-1').change(function() {
      var lap = $(this).val();
      var ap = parseInt(lap);
      if (ap > 500000) {
        var over = ap - 500000;
        var mindp = (500000 * 0.05) + (over * 0.1);
        var minper = ((mindp/ap) * 100).toFixed(6);
        //var minperf = ((mindp/ap) * 100).toFixed(2);
        $('#forminator-field-number-2').attr({'min': minper});
        $('#forminator-field-number-2').val(minper);
      } else if (ap < 500000) {
        $('#forminator-field-number-2').attr({'min': 5.00});
      }
    })

    $('#calculation-4-field').change(function() {
      var lap = $('#forminator-field-currency-1').val();
      var ldp = $('#calculation-2-field').val();
      var ap = parseInt(lap);
      var dp = parseInt(ldp);
      var mort = ap - dp;
      var per = dp/ap;
      var ltv = mort/ap;

      if (per > 0.1999) {
        var ins = 0;
      } else if (per < 0.2 && ltv < 0.65) {
        ins = mort * 0.006;
      } else if (per < 0.2 && ltv >= 0.6501 && ltv <= 0.75) {
        ins = mort * 0.017;
      } else if (per < 0.2 && ltv >= 0.7501 && ltv <= 0.8) {
        ins = mort * 0.024;
      } else if (per < 0.2 && ltv >= 0.801 && ltv <= 0.85) {
        ins = mort * 0.028;
      } else if (per < 0.2 && ltv >= 0.8501 && ltv <= 0.9) {
        ins = mort * 0.031;
      } else if (per < 0.2 && ltv >= 0.901 && ltv <= 0.95) {
        ins = mort * 0.04;
      }
      var fmort = mort + ins;
      insurance = ins;
      $('.accrue-field-amount input').val(fmort);
      $('.accrue-calculate').click();
    });

    // Mortgage Rate
    $('#forminator-field-number-1').change(function() {
    $('input[class="rate"]').val($('#forminator-field-number-1').val());
      $('.accrue-calculate').click();
    });

    // Amortization Period
    $('#forminator-form-327__field--select-3').change(function() {
       var val = $(this).val();
       var num = parseInt(val);
       var str = num+'y';
      $('.accrue-field-term input').val(str);
      $('.accrue-calculate').click();
    });

    // Term
    $('#forminator-form-327__field--select-1').change(function() {
       $('.accrue-calculate').click();
    });

    $('.accrue-calculate').click(function() {
      var i = 0;
      $('.accrue-amortization tr').each(function() {
        i++;
        $(this).addClass('entry-'+i);
      });
      var ask = $('#forminator-field-currency-1').val().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      var ask02 = $('#forminator-field-currency-1').val();
      var askNum = parseInt(ask02);
      var down = $('#calculation-2-field').val().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      var down02 = $('#calculation-2-field').val();
      var downNum = parseInt(down02);
      var bal = $('#forminator-form-327__field--select-1').val();
      var balNum = parseInt(bal);
      var balRow = '.entry-'+((balNum*12)+1);
      var req = (askNum - downNum + insurance).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      var termint = $(balRow + ' .accrue-total-interest').text();
      var termprin = $(balRow + ' .accrue-total-payments').text();
      var pay = $(balRow + ' .accrue-payment-amount').text();
      var termbal = $(balRow + ' .accrue-balance').text();
      var insStr = insurance.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      $('.asking').html('$'+ask);
      $('.down').html('$'+down);
      $('.insurance').html('$'+insStr);
      $('.payment').html(pay);
      $('.req').html('$'+req)
      $('.term-int').html(termint);
      $('.term-prin').html(termprin);
      $('.term-bal').html(termbal);
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
